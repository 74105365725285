const news = {
  namespaced: true,
  state: {
    active: 0,
    LSCType: 1,
    pendingMessages: [],
    approvalProcessesList: [],
    detail: {},
    notificationOption: {},
    notificationDetail: {},
    tableBookingApply: {},
    tableBookingAssistant: [],
    selfOrderApply: {},
    selfOrderGoods: [],
    selfOrderAssistant: [],
    orderSettle: {},
    systematicNotification: {},
    jobNotice: {},
  },
  mutations: {
    getPendingMessages(state, payloud) {
      state.pendingMessages = payloud
    },
    getJobNotice(state, payloud) {
      state.jobNotice = payloud
    },
    getTableJobNotice(state, payloud) {
      state.jobNotice = payloud.option
      state.LSCType = payloud.LSCType
    },
    synchronizationActive(state, payloud) {
      state.active = payloud
    },
    setDetail(state, payloud) {
      state.detail = payloud
    },
    setApprovalProcessesList(state, payloud) {
      state.approvalProcessesList = payloud
    },
    setNotificationDetail(state, payloud) {
      state.notificationOption = {}
      state.notificationDetail = {}
      state.tableBookingApply = {}
      state.tableBookingAssistant = []
      state.selfOrderApply = {}
      state.selfOrderGoods = []
      state.selfOrderAssistant = []
      state.orderSettle = {}

      state.notificationOption = payloud.option
      state.notificationDetail = payloud.details

      if (payloud.option.msg_type === 1) {
        state.tableBookingApply = payloud.tableBookingApply
        state.tableBookingAssistant = payloud.tableBookingAssistant
      } else if (payloud.option.msg_type === 3) {
        state.selfOrderApply = payloud.selfOrderApply
        state.selfOrderAssistant = payloud.selfOrderAssistant
      } else if (payloud.option.msg_type === 2) {
        state.selfOrderApply = payloud.selfOrderApply
        state.selfOrderGoods = payloud.selfOrderGoods
      }

    },
    setTableNotificationDetail(state, payloud) {
      console.log(payloud);
      state.notificationOption = {}
      state.notificationDetail = {}
      state.tableBookingApply = {}
      state.tableBookingAssistant = []
      state.selfOrderApply = {}
      state.selfOrderGoods = []
      state.selfOrderAssistant = []
      state.orderSettle = {}

      state.notificationOption = payloud.option
      state.notificationDetail = payloud.details
      state.LSCType = payloud.LSCType

      if (payloud.option.msg_type === 1) {
        state.tableBookingApply = payloud.tableBookingApply
        state.tableBookingAssistant = payloud.tableBookingAssistant
      } else if (payloud.option.msg_type === 3) {
        state.selfOrderApply = payloud.selfOrderApply
        state.selfOrderAssistant = payloud.selfOrderAssistant
      } else if (payloud.option.msg_type === 2) {
        state.selfOrderApply = payloud.selfOrderApply
        state.selfOrderGoods = payloud.selfOrderGoods
      }

    },
    setSystematicNotification(state, payloud) {
      state.systematicNotification = payloud
    }
  },
  actions: {

  }
}
export default news