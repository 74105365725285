<script setup>
import { reactive, ref, provide, toRaw } from "vue";
import Dialog from "@/components/basicsComponents/Dialog.vue";
import { useStore } from "vuex";

const store = useStore()
let Interval = null
const globalConfig = reactive({
  percentage: 0,
  show: false,
  buttonContent: '开始更新',
  stop: 1,
  confirm: toRaw(() => {
    if (globalConfig.stop === 1) {
      globalConfig.stop = 2
      // globalConfig.buttonContent = '重启'
      globalConfig.buttonContent = '系统更新中...'
      Interval = setInterval(() => {
        if (globalConfig.percentage < 100) {
          globalConfig.percentage++
        } else {
          clearInterval(Interval);
          Interval = null
          globalConfig.stop = 3
          globalConfig.buttonContent = '重启'
        }
      }, 10)
    } else if (globalConfig.stop === 3) {
      store.commit('settle/resetOrder')
      store.commit('charge/resetOrder')
      store.commit('table/resetOrder')
      store.commit('takeOut/resetOrder')
      clearInterval(Interval);
      Interval = null
      globalConfig.show = false
      location.reload()
    }

  })
})

document.title = '非球云智能桌球收银系统'

window.addEventListener('error', (error) => {
  console.log('捕获到异常：', error);
  if (error.target.localName === 'link' || error.target.localName === 'script') {
    globalConfig.stop = 1
    globalConfig.buttonContent = '开始更新'
    globalConfig.percentage = 0
    globalConfig.show = true
  }
}, true)
document.onkeydown = (e) => {
  if (e.target.type === 'button') {
    if (e.target.className === 'van-stepper__plus van-haptics-feedback' || e.target.className === 'van-stepper__minus van-haptics-feedback') {
      if (e.keyCode == 13) {
        return false;
      }
    }
  }
}

function getOSType() {
  if (/(Android)/i.test(navigator.userAgent)) {
    return 0;
  } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    return 1;
  } else {
    return 2;
  }
}

if (getOSType() === 0) {
  navigator.mediaDevices.getUserMedia = navigator.mediaDevices.getUserMedia || navigator.mediaDevices.webkitGetUserMedia || navigator.mediaDevices.mozGetUserMedia || navigator.mediaDevices.msGetUserMedia
  if (navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices.getUserMedia({ video: true }, function onSuccess(stream) {
      console.log('已点击允许,开启成功');
    }, function onError(error) {
      console.log("错误：", error);
    });
  }
}
console.log(!store.state.user.tenantConfig.assistant_global_name || store.state.user.tenantConfig.assistant_global_name.slice(-2));
</script>
<template>
  <div class="app-global-all">
    <router-view />
    <div class="dialogs">
      <Dialog :show="globalConfig.show" :footer="false">
        <template #content>
          <div class="dialog-content" v-if="globalConfig.stop === 1">系统已更新，请点击开始更新</div>
          <div class="dialog-content progress" v-else-if="globalConfig.stop === 2">
            <van-progress :percentage="globalConfig.percentage" />
          </div>
          <div class="dialog-content" v-else-if="globalConfig.stop === 3">系统更新完成，请点击重启</div>
        </template>
        <template #footer>
          <div class="dialog-footer">
            <div class="confirm" @click="globalConfig.confirm">{{ globalConfig.buttonContent }}</div>
          </div>
        </template>
      </Dialog>
    </div>
  </div>
</template>

<style lang="less">
* {
  font-size: 15px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-global-all {
  position: fixed;
  // top: 40px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #1d1d1d;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
<style lang="less" scoped>
.dialog-content {
  text-align: center;
  font-size: 12.5px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.progress {
  padding: 10px 0;
}

.confirm {
  margin: auto;
  cursor: pointer;
  text-align: center;
  height: 30px;
  border-radius: 15px;
  line-height: 30px;
  width: 200px;
  color: #fff;
  background: linear-gradient(135deg, #42A0FF 0%, #01C6BD 100%);
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
}

.dialogs {
  :deep(.van-popup) {
    background-color: transparent;
    border-radius: 15px;
  }
}
</style>
